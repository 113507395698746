export const colorsTheme = {
  white: '#FFFFFF',
  black: '#000000',
  danger: '#F2383A',

  'gradient-deg': '294deg',
  'gradient-primary': '#B02A37',
  'gradient-secondary': '#000',

  'primary-color': '#B02A37',
  'secondary-color': '#842029',
  'auxiliary-color': '#F2383A',

  'bg-color': '#FFFFFF',
  'bg-secondary-color': '#121212',
  'bg-auxiliary-color': '#343A40',

  'bg-circle-color': '#343a40',
  'bg-circle-hover': '#CD202F',
  'bg-circle-text-color': '#000',
  'bg-circle-text-color-hover': '#FFFFFF',

  'filter-button-background': '#CD202F',
  'filter-button-color': '#FFFFFF',
  'filter-button-border': '#CD202F',
  'entry-message-color': '#CD202F',

  'bg-modal-color': '#343a40',

  'dark-color': '#3F3F3F',

  'text-primary': '#FFFFFF',
  'text-secondary': '#FFFFFF;',
  'text-gray': '#4F4F4F',

  'input-color': '#212529',
  'bg-input-color': '#FFFFFF',
  'info-color': '#6c757d',
  'disabled-color': '#6c757d',
  'card-color': '#495057',
  'modal-border-color': '#ADB5BD',
  'border-color': '#FFFFFF',

  'login-background':
    'https://internet-banking-assets.s3.amazonaws.com/coral/login-background.png',
  'login-background-complement': '#121212',

  'echeck-label-color': '#000',
  'echeck-border-color': '#D2D2D2',
  'echeck-text-color': '#000',
  'echeck-bar-color1': '#B02A37',
  'echeck-bar-color2': '#B02A37'
};
